import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="star" />
            Our Solutions
          </span>
          <h2>Modern Solutions for Modern Platforms</h2>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h3>
                <Link to="/service-details">CVSS 3.1 For Jira Cloud</Link>
              </h3>

              <p>
                CVSS 3.1 for Jira Cloud provides a new custom field type that
                allows the user to score and track issues in an easy, fast way
                using the CVSS 3.1 scoring system.
              </p>
              <Link to="/docs/cvss-3-1-for-jira" className="default-btn">
                <i className="flaticon-web"></i>
                Go To Docs
                <span></span>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                <Link to="/service-details">Just Getting Started</Link>
              </h3>
              <p>
                We're a brand new startup actively working on new solutions for
                you. Stay tuned for more great offerings.
              </p>

              {/* <Link to="/service-details" className="view-details-btn">
                View Details
              </Link> */}
            </div>
          </div>
          {/*
          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h3>
                <Link to="/service-details">Access Controlled</Link>
              </h3>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et.
              </p>

              <Link to="/service-details" className="view-details-btn">
                View Details
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
